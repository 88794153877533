import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import styles from './RunningArrows.module.scss'
import { Direction } from '@Types'

const images = [
    { src: '/img/arwAnimation/arwGreyToRight.svg', id: 1 },
    { src: '/img/arwAnimation/arwGreyToRight.svg', id: 2 },
    { src: '/img/arwAnimation/arwGreyToRight.svg', id: 3 },
]

export const RunningArrows = ({
    type = Direction.Right,
}: {
    type?: string
}): JSX.Element => {
    const start = 1

    const [count, setCount] = useState(start)

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (count < 3) {
                setCount((prev) => prev + 1)
            } else {
                setCount(start)
            }
        }, 333)
        return () => clearTimeout(timerId)
    }, [count])

    return (
        <div className={cx(styles.container, styles[type])}>
            {images.map((image, index) => (
                <svg
                    viewBox="0 0 5 8"
                    xmlns="http://www.w3.org/2000/svg"
                    key={index}
                    className={cx(
                        index < count ? styles.active : styles.arrow,
                        styles.size
                    )}
                >
                    <path d="M3.57143 4L0 0L1.42857 0L5 4L1.42857 8H0L3.57143 4Z" />
                </svg>
            ))}
        </div>
    )
}
