import React from 'react'
import { Speaker, EmptySpeaker } from '@Types'
import styles from './SpeakerCard.module.scss'
import cx from 'classnames'
import Link from 'next/link'
import LazyImage from '@Components/LazyImage/LazyImage'

const SpeakerCard = ({
    speaker,
    hasMask = true,
}: {
    speaker: Speaker | EmptySpeaker
    hasMask?: boolean
}) => {
    const { empty } = speaker as EmptySpeaker
    const { name, slug, image, position } = speaker as Speaker

    if (empty) {
        return (
            <div className={styles.emptySpeaker}>
                <div className={styles.mask}>
                    <div className={styles.info}>
                        <h4 className={styles.emptySpeakerTitle}>
                            THE PLACE FOR <br />
                            FUTURE SPEAKERS
                        </h4>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Link href={`/review/${slug}`}>
            <a className={styles.link}>
                <div
                    className={cx(styles.avatar, {
                        [styles.mask]: hasMask,
                        [styles.border]: !hasMask,
                    })}
                >
                    <LazyImage
                        className={styles.avatarImg}
                        src={image}
                        alt={name}
                    />
                    <div className={styles.info}>
                        <h3 className={styles.name}>{name}</h3>
                        <p className={styles.caption}>{position}</p>
                    </div>
                </div>
            </a>
        </Link>
    )
}

export default SpeakerCard
