import styles from './SpeakersSlider.module.scss'
import { Direction, EmptySpeaker, Speaker } from '@Types'
import SpeakerCard from '@Components/SpeakerCard/SpeakerCard'
import { PaginationButton } from '@Components/PaginationButton/PaginationButton'
import { useEffect, useRef, useState } from 'react'
import Slider from '@Components/Slider/Slider'
import { RunningArrows } from '@Components/RunningArrows/RunningArrows'

const SpeakersSlider = ({ speakers }: { speakers: Speaker[] }) => {
    const flickityRef = useRef<any>(null)

    const [mappedSpeakers, setMappedSpeakers] = useState<
        (Speaker | EmptySpeaker)[]
    >([])

    useEffect(() => {
        if (speakers.length < 4) {
            const emptySpeakers = Array.of(4 - speakers.length).map(() => {
                return { empty: true, id: Math.random() }
            })
            setMappedSpeakers([...speakers, ...emptySpeakers])
        }
        setMappedSpeakers(speakers)
    }, [])

    const goNext = () => {
        flickityRef.current.next()
    }

    const goPrev = () => {
        flickityRef.current.previous()
    }

    const options = {
        initialIndex: 0,
        groupCells: 4,
        cellAlign: 'left',
        pageDots: false,
        wrapAround: false,
        prevNextButtons: false,
        freeScroll: true,
    }

    return (
        <div className={styles.container} id="speakers">
            <div className={styles.info}>
                <div className={styles.arrowsWrapper}>
                    <h4 className={styles.block}>block: 000-02</h4>
                    <RunningArrows />
                </div>

                <h2>SPEAKERS</h2>
                <div className={styles.arrowsWrapper}>
                    <RunningArrows type={Direction.Left} />
                    <h4 className={styles.block}>block: 000-02</h4>
                </div>
            </div>
            <div className={styles.sliderContainer}>
                <Slider flickityRef={flickityRef} options={options}>
                    {mappedSpeakers.map((speaker, index) => (
                        <SpeakerCard
                            key={speaker.id}
                            speaker={speaker}
                            hasMask={!index}
                        />
                    ))}
                </Slider>
                <div className={styles.control}>
                    <PaginationButton
                        onClick={goPrev}
                        className={styles.button}
                        direction={Direction.Left}
                    />
                    <PaginationButton
                        onClick={goNext}
                        className={styles.button}
                        direction={Direction.Right}
                    />
                </div>
            </div>
            <a href="mailto:mike@flowmapp.com">
                <div className={styles.plusTextWrapper}>
                    <h4 className={styles.email}>become our speaker</h4>
                    <h4 className={styles.plusRotate}>
                        &nbsp; [<div className={styles.plus}>+</div>]
                    </h4>
                </div>
            </a>
        </div>
    )
}

export default SpeakersSlider
