import styles from './ReviewCardMain.module.scss'
import SpeakerInfo from '@Components/SpeakerInfo/SpeakerInfo'
import { Review, Speaker, SpeakerSize } from '@Types'
import { formatDate } from '@Utils/date'
import cx from 'classnames'
import Link from 'next/link'
import LazyImage from '@Components/LazyImage/LazyImage'

const ReviewCardMain = ({
    speaker,
    review,
    isReversed,
}: {
    speaker: Speaker
    review: Review
    isReversed: boolean
}) => {
    return (
        <Link href={`/review/${speaker.slug}`}>
            <a className={styles.link}>
                <div
                    className={cx(styles.container, {
                        [styles.reverse]: isReversed,
                    })}
                >
                    <div className={styles.imageCover}>
                        <LazyImage
                            className={styles.img}
                            src={review.mainImage}
                            alt={review.mainImage.name}
                        />
                        <h5 className={styles.username}>
                            {review?.authorName}
                        </h5>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.projectInfo}>
                            <h4 className={styles.date}>
                                {formatDate(review.date, 'short')}
                            </h4>
                            <h4>{review.number}</h4>
                        </div>
                        <h4 className={styles.title}>{review.title}</h4>
                        <div className={styles.userInfo}>
                            <SpeakerInfo
                                speaker={speaker}
                                size={SpeakerSize.Small}
                                title={review.title}
                            />
                            <div className={styles.marquee}>
                                <h4 className={styles.marquee}>→ → →</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    )
}

export default ReviewCardMain
