import React from 'react'
import styles from './MainReview.module.scss'
import { IMainReviewer, SpeakerSize } from '@Types'
import { formatDate } from '@Utils/date'
import LazyImage from '@Components/LazyImage/LazyImage'
import Link from 'next/link'
import SpeakerInfo from '@Components/SpeakerInfo/SpeakerInfo'

const MainReview = ({ mainReview }: { mainReview: IMainReviewer | null }) => {
    if (!mainReview) {
        return <div className={styles.container} />
    }

    const {
        slug,
        date,
        number,
        reviewTitle,
        speakerName,
        speakerPosition,
        reviewSubtitle,
        aboutSpeaker,
        speakerPhoto,
        reviewImage,
    } = mainReview

    const speaker = {
        name: speakerName,
        position: reviewTitle,
        image: speakerPhoto,
    }
    return (
        <div className={styles.container}>
            <div className={styles.reviewInfo}>
                <h4 className={styles.date}>{formatDate(date, 'short')}</h4>
                <h4>{number}</h4>
            </div>
            <Link href={`/review/${slug}`}>
                <a>
                    <LazyImage
                        className={styles.personImage}
                        src={reviewImage}
                        alt={speakerName}
                    />
                </a>
            </Link>
            <div className={styles.personInfo}>
                <div className={styles.personAbout}>
                    <h2 className={styles.personName}>{reviewTitle}</h2>
                    <h4 className={styles.personSubtitle}>{reviewSubtitle}</h4>
                </div>
            </div>
            <div className={styles.personTablet}>
                <SpeakerInfo
                    speaker={speaker}
                    title={reviewTitle}
                    size={SpeakerSize.Small}
                />
                <h4 className={styles.aboutSpeaker}>{aboutSpeaker}</h4>
                <div className={styles.marquee}>
                    <h4 className={styles.marquee}>→ → →</h4>
                </div>
            </div>
        </div>
    )
}

export default MainReview
