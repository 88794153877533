import SpeakersSlider from '@Modules/SpeakersSlider/SpeakersSlider'
import Reviews from '@Modules/Reviews/Reviews'
import {
    fetchMainReview,
    fetchPage,
    fetchReviews,
    fetchReviewsCount,
    fetchSpeakers,
} from '@Api'
import { IMainReviewer, IPage, Review, Speaker } from '@Types'
import { PageHead } from '@Components/Seo/PageHead'
import MainReview from '@Components/MainReview/MainReview'

const initialLimit = 5

const Home = ({
    reviews,
    count,
    speakers,
    mainReview,
    page,
}: {
    reviews: Review[]
    count: number
    speakers: Speaker[]
    mainReview: IMainReviewer | null
    page: IPage
}) => {
    const reviewsWithoutMainReview: Review[] = reviews.filter(
        (review) => review.speaker.slug !== mainReview?.slug
    )
    return (
        <>
            <PageHead entity={page} type="page" />
            <MainReview mainReview={mainReview} />
            <Reviews
                items={reviewsWithoutMainReview}
                count={count}
                speakerSliders={speakers}
                initialLimit={initialLimit}
            />
            <SpeakersSlider speakers={speakers} />
        </>
    )
}

export async function getStaticProps() {
    const [reviews, count, speakers, mainReview, page] = await Promise.all([
        fetchReviews({ start: 0, limit: initialLimit }),
        fetchReviewsCount(),
        fetchSpeakers(),
        fetchMainReview(),
        fetchPage('home-page'),
    ])

    return {
        props: {
            reviews,
            count,
            speakers,
            mainReview,
            page,
        },
        revalidate: 600,
    }
}

export default Home
