import { useEffect, useState } from 'react'
import styles from './Reviews.module.scss'
import cx from 'classnames'
import SpeakerSlider from '@Modules/SpeakerSlider/SpeakerSlider'
import { Button } from '@Components/Button/Button'
import { Direction, Review, Speaker } from '@Types'
import { fetchReviews } from '@Api'
import ReviewCardMain from '@Components/ReviewCardMain/ReviewCardMain'
import { RunningArrows } from '@Components/RunningArrows/RunningArrows'

const Reviews = ({
    count,
    items,
    speakerSliders,
    initialLimit,
}: {
    count: number
    items: Review[]
    speakerSliders: Speaker[]
    initialLimit: number
}) => {
    const shuffledCards: (Review | Speaker)[] = []
    const copiedSpeakersSlider: Speaker[] = [...speakerSliders]

    const [reviews, setReviews] = useState(items)
    const [limit, setLimit] = useState(initialLimit)
    const [pageNumber, setPageNumber] = useState(0)
    const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        setHasMore(reviews.length < count)
    }, [reviews, pageNumber])

    for (let i = 0; i < reviews.length; i++) {
        const serialNumber = i + 1
        const shouldRenderSpeaker = serialNumber % 3 === 0
        const hasSpeakers = copiedSpeakersSlider.length > 0

        if (!shouldRenderSpeaker) {
            shuffledCards.push(reviews[i])
            shuffledCards.push()
        }

        if (shouldRenderSpeaker && hasSpeakers) {
            const item = copiedSpeakersSlider.shift()
            shuffledCards.push(item as Speaker)
        }
    }

    const handleLoadMore = async () => {
        const nextPage = pageNumber + 1
        const newReviews = await fetchReviews({
            start: nextPage * limit,
            limit,
        })
        setPageNumber(nextPage)

        setReviews([...reviews, ...newReviews])
    }

    return (
        <div className={styles.container} id="reviews">
            <div className={styles.info}>
                <div className={styles.arrowsWrapper}>
                    <h4 className={styles.block}>block: 000-01</h4>
                    <RunningArrows />
                </div>
                <h2 className={styles.title}>REVIEWS</h2>
                <div className={styles.arrowsWrapper}>
                    <RunningArrows type={Direction.Left} />
                    <h4 className={styles.block}>block: 000-01</h4>
                </div>
            </div>
            <div className={styles.reviews}>
                {shuffledCards.map((card, index) => {
                    if ('name' in card && card.review) {
                        return (
                            <SpeakerSlider
                                key={card.name}
                                reviewCards={card.review.reviewCards}
                                speaker={card}
                            />
                        )
                    }

                    if ('speaker' in card && index % 3 === 0 && index !== 0) {
                        return (
                            <div key={card.id}>
                                <ReviewCardMain
                                    isReversed={index % 2 !== 0 && index !== 0}
                                    review={card}
                                    speaker={card.speaker}
                                />
                                <div className={styles.animation}>
                                    <div className={styles.runningTextWrapper}>
                                        <span className={styles.runningText1}>
                                            <div className={styles.divider}>
                                                {'///'}
                                            </div>
                                            <h4 className={styles.runningTitle}>
                                                William J. Guerra: 3D motion
                                                elements and Surrealistic visual
                                                combinations
                                            </h4>
                                            <div className={styles.divider}>
                                                {'///'}
                                            </div>
                                            <h4 className={styles.runningTitle}>
                                                Abdul Malone: Personalized
                                                Content and Human-Like Chatbots
                                            </h4>
                                            <div className={styles.divider}>
                                                {'///'}
                                            </div>
                                            <h4 className={styles.runningTitle}>
                                                Shayne Mckeown: web Brutalism
                                                and Antidesign
                                            </h4>
                                        </span>
                                    </div>
                                    <div className={styles.runningTextWrapper}>
                                        <span className={styles.runningText2}>
                                            <div className={styles.divider}>
                                                {'///'}
                                            </div>
                                            <h4 className={styles.runningTitle}>
                                                William J. Guerra: 3D motion
                                                elements and Surrealistic visual
                                                combinations
                                            </h4>
                                            <div className={styles.divider}>
                                                {'///'}
                                            </div>
                                            <h4 className={styles.runningTitle}>
                                                Abdul Malone: Personalized
                                                Content and Human-Like Chatbots
                                            </h4>
                                            <div className={styles.divider}>
                                                {'///'}
                                            </div>
                                            <h4 className={styles.runningTitle}>
                                                Shayne Mckeown: web Brutalism
                                                and Antidesign
                                            </h4>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if ('speaker' in card) {
                        return (
                            <ReviewCardMain
                                key={card.id}
                                isReversed={index % 2 !== 0 && index !== 0}
                                review={card}
                                speaker={card.speaker}
                            />
                        )
                    }
                })}
            </div>
            {hasMore && (
                <div className={styles.loadMoreWrapper}>
                    <Button
                        onClick={handleLoadMore}
                        className={styles.loadMore}
                    >
                        <div className={styles.buttonTextWrapper}>
                            <h4 className={styles.loadMoreText}>
                                more reviews
                            </h4>
                            <h4 className={styles.arrowDown}>
                                [<div className={styles.arrow}>↓</div>]
                            </h4>
                        </div>
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Reviews
