import styles from './SpeakerSlider.module.scss'
import Slider from '@Components/Slider/Slider'
import { useRef } from 'react'
import { PaginationButton } from '@Components/PaginationButton/PaginationButton'
import { Direction, IReviewCard, Speaker } from '@Types'
import SpeakerCard from '@Components/SpeakerCard/SpeakerCard'
import Link from 'next/link'
import LazyImage from '@Components/LazyImage/LazyImage'

const SpeakerSlider = ({
    reviewCards,
    speaker,
}: {
    reviewCards?: IReviewCard[]
    speaker: Speaker
}) => {
    const options = {
        initialIndex: 0,
        groupCells: 3,
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: false,
        freeScroll: true,
    }

    const flickityRef = useRef<any>(null)

    const goNext = () => {
        flickityRef.current.next()
    }

    const goPrev = () => {
        flickityRef.current.previous()
    }

    if (!reviewCards) {
        return <></>
    }

    return (
        <div className={styles.container}>
            <div className={styles.slider}>
                <SpeakerCard speaker={speaker} />
                <div className={styles.reviewsContainer}>
                    <Slider flickityRef={flickityRef} options={options}>
                        {reviewCards.map((reviewCard) =>
                            reviewCard.info.map((info) => (
                                <Link
                                    key={info.id}
                                    href={`/review/${speaker.slug}`}
                                >
                                    <a className={styles.link}>
                                        <LazyImage
                                            className={styles.item}
                                            src={info?.image}
                                            alt={info?.image?.name}
                                        />
                                    </a>
                                </Link>
                            ))
                        )}
                    </Slider>
                    <div className={styles.control}>
                        <PaginationButton
                            onClick={goPrev}
                            className={styles.button}
                            direction={Direction.Left}
                        />
                        <PaginationButton
                            onClick={goNext}
                            className={styles.button}
                            direction={Direction.Right}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sliderMobile}>
                <div className={styles.reviewsContainer}>
                    <Slider options={options}>
                        <SpeakerCard speaker={speaker} />
                        {reviewCards.map((reviewCard) =>
                            reviewCard.info.map((info) => (
                                <Link
                                    key={info?.image?.id}
                                    href={`/review/${speaker.slug}`}
                                >
                                    <a className={styles.link}>
                                        <LazyImage
                                            className={styles.item}
                                            src={info?.image}
                                            alt={info?.image?.name}
                                        />
                                    </a>
                                </Link>
                            ))
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default SpeakerSlider
